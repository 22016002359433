export const defaultValues = {
  PROPERTY_VALUE: 4500000,
  DEPOSIT: 1000,
  DURATION: "25",
  INTEREST: "3.5",
  DEFAULT_RESULT: false,
  INSTANT_RESULT: false,
  CURRENCY: "AED ",
  PRICE_PREFIX: true,
  DURATION_SUFFIX: true,
  YEAR_SUFFIX: true,
  YEAR_SELECT: true,
}
