import React, { useState } from "react"
import { Container, Row, Col } from "react-bootstrap";
import HTMLReactParser from 'html-react-parser';
import ProductInfo from "../ProductInfo/ProductInfo";
import LocationMap from "../../../images/location-map.jpg";
import LocationMapTablet from "../../../images/location-map-tablet.jpg";
import Map from "../../map/google/single"
import Calculator from "./Calculator";
import "./ProductContent.scss"
import { CurrConvertPrice } from "../../currency-converter/CurrConvertPrice";
//import ScrollAnimation from "react-animate-on-scroll";
// styles

// markup

const ProductContent = (props) => {
  const [isPlay, setPlay] = useState(false);
  const [current, setCurrent] = useState('');
  const handleCurrConvert = (childData) =>{
    setCurrent(childData)
  }
  return (

    <React.Fragment>
      <div className="property-details">
        <Container className="pad-50">
          <Row>
            <Col xl={4}>
              <div className="left-sticky">
                <ProductInfo data={props.data} handleCurrConvert={handleCurrConvert}/>
              </div>


            </Col>
            <Col xl={8}>
              <div className="property-details-content">
                {props.data && props.data.accomadation_summary &&
                  <div animateIn="fadeInUp" animateOnce>
                    <div className="key-feature-block property-details-block">
                      <h2>Key Features</h2>
                      <ul className="key-feature-list">
                        {props.data && props.data.accomadation_summary.map((data, i) => {
                          if (data != "") {
                            return (<li> <span className="list">{data}</span> </li>)
                          } 
                        })}
                      </ul>
                    </div>
                  </div>
                }
                {props.data && props.data.long_description &&
                  <div animateIn="fadeInUp" animateOnce>
                    <div className="property-description property-details-block">
                      <h2>Description</h2>
                      {props.data.long_description.substring(0,4).toLowerCase() === "<br>" ? HTMLReactParser(props.data.long_description).slice(4) : HTMLReactParser(props.data.long_description)}
                    </div>
                  </div>
                }

                {/*  <div animateIn="fadeInUp" animateOnce>
                    <div className="property_amenity_block property-details-block">
                      <h2>Property Amenities</h2>
                      <ul>
                        <li>
                          <i className="icon-balcony"></i> Balcony

                        </li>
                        <li>
                          <i className="icon-pool"></i> Shared Pool

                        </li>
                        <li>
                          <i className="icon-gym"></i> Shared Gymcc

                        </li>
                        <li>
                          <i className="icon-security"></i> Security

                        </li>
                      </ul>
                    </div>
</div> */}

                {props.data.latitude ?
                  <div animateIn="fadeInUp" animateOnce>
                    <div className="location-block property-details-block">
                      <h2>Location</h2>
                      <div className="location-block-map">
                        <Map image={props.data.images} lat={props.data.latitude ? parseFloat(props.data.latitude) : ''} lng={props.data.longitude ? parseFloat(props.data.longitude) : ''} height={"600px"} zoom={5} OfficeName={props.data.title} OfficeAddress={props.data.address?.area} />
                      </div>
                    </div>
                  </div> : ''
                }
                {
                  props.data.search_type == "sales" &&
                  <Calculator price={CurrConvertPrice(props.data.price.toLocaleString()).slice(3).replace(/,/g, '')} extras={props.data.extras} current={current}/>
                }

              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProductContent