import * as React from "react"
import { Link } from "@StarberryUtils";

import { Container, Row, Col, Tabs, Tab } from "react-bootstrap"
import img01 from "../../../images/home/Properties/img01.png"
import tab01 from "../../../images/home/Properties/tab-img01.png"
import img02 from "../../../images/home/Properties/img02.png"
import desk01 from "../../../images/home/Properties/desk01.png"
import desk02 from "../../../images/home/Properties/desk02.png"
import desk03 from "../../../images/home/Properties/desk03.png"
import desk04 from "../../../images/home/Properties/desk04.png"
import labelIMG from "../../../images/label.png"
//import ScrollAnimation from "react-animate-on-scroll";
import Sale from './sales';
import Let from './lettings';
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import _ from "lodash"
import Slider from "react-slick";

import "./Properties.scss"

const SimProperties = (props) => {
  let [isPlay, setPlay] = React.useState(false);
  const [key, setKey] = React.useState('sale');


  const settings = {
    dots: true,
lazyLoad: true,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 2,
    infinite: false,
    arrows: true,
    mobileFirst: true,
    autoplay: true,
    responsive: [

      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1

        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        },
      },
    ],
  }
  return (
    <div animateIn='fadeInUp' animateOnce className="properties">
      {/* <section className="properties"> */}
      <Container className="pad-50 similar-properties">
        <h2>Similar Properties in {props.place}</h2>
        <Row>
          <Col lg={12} className="property-details-similar">

            <Slider  {...settings}>
              {props.data && props.data.map((item, index) => {
                // property details url structure
                let uriStr = "";

                if (item.search_type === "sales" && props.tag != "commercial") {
                  uriStr = `properties-for-sale/`
                } else if (item.search_type === "lettings") {
                  uriStr = `properties-for-rent/`
                }

                if (props.tag == "commercial") {
                  if (item.search_type === "sales") {
                    uriStr = `commercial-properties-for-sale/`
                  } else if (item.search_type === "lettings") {
                    uriStr = `commercial-properties-for-rent/`
                  }
                }

                if (props.tag == "new-homes") {
                  if (item.search_type === "sales") {
                    uriStr = `new-homes-for-sale/`
                  } else if (item.search_type === "lettings") {
                    uriStr = `new-homes-for-rent/`
                  }
                }
                // property details url structure 

                var image_url = img01;
                if (!_.isEmpty(item.images)) {
                  image_url = item.images[0].url
                }

                let processedImages = JSON.stringify({});
                if (item?.imagetransforms?.images_Transforms) {
                  processedImages = item.imagetransforms.images_Transforms;
                }
                if (!_.isEmpty(item.images)) {
                  return (

                    <div className="all-products img-zoom">
                      <Link to={'/' + uriStr + item.slug.replace(/_/g, '-') + '-' + item.id}>
                        <div className="properties-wrapper ">

                          <ImageTransform imagesources={image_url} renderer="srcSet" imagename="property.images.similar_properties" attr={{ alt: item.address?.area + ' - Dacha', class: '' }} imagetransformresult={processedImages} id={item._id} />

                          {/*<div className="properties-btn">
                        <storng className="video-btn" onClick={e => { setPlay(true) }}>
                          <i className="icon-videos">
                          </i>
                        </storng>
                      </div> */}
                        </div>
                      </Link>
                      <div className="about-properties">

                        {item.bedroom !== 0 || item.bathroom !== 0 || item.floor_area ?
                          <div className="properties-icons">
                            {item.department == "residential" && item.bedroom !== 0 &&
                              <span className="text">
                                <i className="icon-bedroom">
                                </i>
                                <span class="sm-text ">
                                  {item.bedroom}
                                </span>
                              </span>
                            }
                            {item.department == "residential" && item.bathroom !== 0 &&
                              <span className="text">
                                <i className="icon-bath">
                                </i>
                                <span class="sm-text">
                                  {item.bathroom}
                                </span>
                              </span>
                            }
                            {item.floor_area !== 0 &&
                              <span className="text">
                                <i className="icon-space">
                                </i>
                                <span class="sm-text">
                                  {item.floor_area} ft<sup>2</sup>
                                </span>
                              </span>
                            }
                          </div> : <div className="properties-icons">&nbsp;</div>
                        }
                        <div className="properties-info">
                          <span className="total">
                            AED {item.price.toLocaleString()} {(item.search_type == "lettings") ? 'per year' : null}
                          </span>
                          <h2>
                            <Link to={'/' + uriStr + item.slug.replace(/_/g, '-') + '-' + item.id}>
                              {item.title}
                            </Link>
                          </h2>
                          <span className="about-location">
                            <i className="icon-location">
                            </i>
                            <span className="location-info">
                              {item?.address.Address && (item?.address.Address + ", ")}{item?.address.area && (item?.address.area + ", ")} {item?.address.town && (item?.address.town)}                            </span>
                          </span>
                        </div>
                      </div>

                    </div>
                  )
                }

              })
              }

            </Slider>


          </Col>
        </Row>

      </Container>
      {/* </section> */}
    </div>
  )
}

export default SimProperties