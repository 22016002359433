/**
 * Mortgage Calculator
 *
 * You can changes the default values in config
 *
 * DEV:NOTES
 * Please do not do nay changes in the DO NOT DO ANY CHNAGES block
 * Do import in use project specific import here
 * And play with the render layout structure and keep the params
 */

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Row, Col, Button, Form } from 'react-bootstrap';
import { filterNumber, numberFormat } from "./util"
import Select from 'react-select';
import {
  calculate,
  countries,
  // propertyTypes,
  // buyerTypes,
} from "uk-ireland-stampduty-calculator"

// we could user uk-ireland-stampduty-calculator's propertyTypes & buyerTypes
// but key val par diff we could get valus using Object.values but it is not
// supported in IE - https://caniuse.com/#feat=object-values
// so defined our our custom key pairs
const propertyTypes = [
  {
    value: "residential",
    label: "Residential",
  },
  {
    value: "commercial",
    label: "Commercial",
  },
]

const buyerTypes = [
  {
    value: "first",
    label: "First Time Buyer",
  },
  {
    value: "home",
    label: "Home Mover (main residence and not a first time buyer)",
  },
  {
    value: "investor",
    label: "Second Homes / BTL",
  },
]

// use project specific import here

// DO NOT DO ANY CHNAGES - START
const StampdutyCalc = props => {
  const currency = props.currency
  const title = props.title

  const [result, setResult] = useState(false)

  const [propertyType, setPropertyType] = useState(props.propertyType)
  const [propertyValue, setPropertyValue] = useState(
    currency + numberFormat(filterNumber(props.propertyValue))
  )
  const [buyer, setBuyer] = useState(props.buyerType)

  const formatPrice = str => {
    return currency + str.toLocaleString("en-US")
  }

  const styles = {
    control: styles => ({ ...styles, backgroundColor: '#ffffff', border: 0, padding:'10px', borderRadius: '8px',  outline: 0, boxShadow: 'none', fontSize: '1rem', color: '#263140', minHeight: '3rem', paddingLeft: '1rem', marginBottom: '1rem' }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: '#263140',
      }
    },
    menu: (provided, state) => ({
      ...provided,
     zIndex:99
    }),
  }
  

  const doCalculate = () => {
    const results = calculate(
      filterNumber(propertyValue),
      propertyType,
      countries.ENGLAND,
      buyer
    )
    // effective stamp duty rates
    // Formula => ((tax / price) * 100).
    // Ex: (213750/2500000) * 100 = 8.55%.
    let effectiveRate = (results.tax / filterNumber(propertyValue)) * 100
    effectiveRate = new Intl.NumberFormat("en-US", {}).format(effectiveRate)

    if(effectiveRate === "NaN")
    {
      effectiveRate = 0
    }

    let summaryBands = []
    results.summaryBands.map(result => {
      summaryBands.push(
        result.adjustedRate +
          "% between " +
          formatPrice(result.start) +
          " and " +
          formatPrice(result.end)
      )
    })
    const result = {
      effectiveRate: effectiveRate + "%",
      summaryBands: summaryBands,
      tax: formatPrice(results.tax),
    }
    setResult(result)
  }

  const handleSubmit = event => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'Calculator',
        'formType': title,
        'formId': "Stamp Duty Calculator",
        'formName': "Stamp Duty Calculator",
        'formLabel': "Stamp Duty Calculator"
      });
    event.preventDefault()
    event.stopPropagation()
    doCalculate()
  }

  const handleDeposit = event => {
    setPropertyValue(currency + numberFormat(filterNumber(event.target.value)))
  }

  const handlePropertyType = event => {
    setPropertyType(event.value)
  }

  const handleBuyer = event => {
    setBuyer(event.value)
  }
  useEffect(() => {
    doCalculate()
  },[propertyType, buyer])

  // DO NOT DO ANY CHNAGES - END

  return (
    <div className="form mortgate-form">
      <Form
        name="MortgageCalc"
        noValidate
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="bot-field" />

      <Row>

        <Col md={3}>


          <Form.Group controlId="property_type">
            <div className="custom-float custom-selectbox">  
              <Form.Label className="form-field-label">Property Type</Form.Label>
              <Select
                name="property_type"
                defaultValue={{
                  value: "residential",
                  label: "Residential",
                }}
                options={propertyTypes}
                onChange={handlePropertyType}
                placeholder="Property Type"
                styles={styles}
                className={`customselectbox`}
              /> 
            </div>
          </Form.Group>
        </Col>


        <Col md={3}>
          <Form.Group controlId="deposit">
            <div className="custom-float">
              <Form.Label className="form-field-label">Property Value</Form.Label>
              <Form.Control 
                required
                type="text"
                name="deposit"
                value={propertyValue}
                placeholder="Purchase Price"
                onChange={handleDeposit}
                disabled
              />
            </div>
          </Form.Group>
        </Col>

        <Col md={6}>
          <Form.Group controlId="buyer">
            <div className="custom-float custom-selectbox">
              <Form.Label className="form-field-label">Buyer Type</Form.Label>
              <Select
                name="buyer"
                defaultValue={{
                  value: "first",
                  label: "First Time Buyer",
                }}
                options={buyerTypes}
                onChange={handleBuyer}
                placeholder="Buyer Type"
                styles={styles}
                className={`customselectbox`}
              />  
            </div>
          </Form.Group>
        </Col>
 

      </Row>


        <Form.Row>

        {/*<Col md={3}>
        <Button type="submit" className="btn btn-pink">
          Calculate
        </Button>
        </Col>*/}
        <Col md={6} className="">
          {result && (
            <div className="monthly-payment investment-monthly-payment">
                <h3>Stamp Duty</h3>
                <strong className="pay d-block  monthly-amount" >{result.tax}</strong>
                <h4>You have to pay stamp duty:</h4>
                {result.summaryBands.map((sm, i) => {
                    return <h3>{sm}</h3>
                  })}
                  <div className="stamp-result-1 pay">
                    Your effective stamp duty rate is {result.effectiveRate}
                  </div>
                </div>
          )}
        </Col>
        </Form.Row>
      
      </Form>
    </div>
  )
}

StampdutyCalc.defaultProps = {
  propertyType: `residential`,
  propertyValue: `0`,
  buyerType: `first`,
  currency: process.env.CURRENCY ? process.env.CURRENCY : "AED ",
}

StampdutyCalc.propTypes = {
  propertyType: PropTypes.string,
  propertyValue: PropTypes.any,
  buyerType: PropTypes.string,
  currency: PropTypes.string,
}

export default StampdutyCalc
