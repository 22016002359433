import React, { useState, useEffect } from "react";
import { Link } from "@StarberryUtils";
import { graphql } from 'gatsby'
import { useQuery } from '@apollo/client';
import HTMLReactParser from 'html-react-parser';
import gql from "graphql-tag"
import axios from 'axios';
import { Container } from 'react-bootstrap';
import Logo from '../images/dacha-logo-blue.png';
import Breadcrumb from "../components/Home/Breadcrumb/Breadcrumb";

import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import NewsLetter from "../components/Home/NewsLetter/NewsLetter";
import PropertyBanner from "../components/PropertyDetails/PropertyBanner/PropertyBanner";
import ProductContent from "../components/PropertyDetails/ProductContent/ProductContent";
import StickyButton from "../components/PropertyDetails/StickyButton/StickyButton";
import Similar from "../components/Home/Properties/PropertyDetailsSimilar";
import SEO from "../components/seo";
import NotFound from "../components/404Page/404Page";
import _ from 'lodash'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/scss/bootstrap.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../scss/bootstrap-overrides.scss";
import "../scss/grunticon.scss";
import "../scss/global.scss";
import "../scss/custom.scss";
import { navigate } from "@reach/router";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { data } from "jquery";

const PROPERTY_DETAILS = gql`
  query GetProperty ($id: String!){  
    properties(where:{id: $id, publish:true}) {
      id
      title
      display_address
      address
      price
      bedroom
      bathroom
      reception
      brochure
      building
      images
      floorplan
      video_link
      virtual_tour
      long_description
      latitude
      longitude
      accomadation_summary
      department
      floor_area
      imagetransforms
      search_type
      crm_negotiator_id
      negotiator_id
      crm_id
      extras
      status
      publish
    }
  }
`;


function PropertyDetailsTemplate(props) {

  let [reviewCount, setReviewCount] = React.useState(0);
    let [averageRating, setAverageRating] = React.useState(0);
    const wordToNumber = { 'ONE': 1, 'TWO': 2, 'THREE': 3, 'FOUR': 4, 'FIVE': 5 };
    const strapiconfigs = {
        headers: {
            Authorization:
                `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
        },
    }
     const urlReviws = `${process.env.GATSBY_STRAPI_SRC}/google-review-reviews`;
      const getitemreviews = async url => {
        try {
          const { data } = await axios.get(url,strapiconfigs)// could be from env files 
          setReviewCount(data.length);
          let sumOfScores = 0;
          data.map((review) => {
            const reviewStringValue = review.starRating;
            sumOfScores += wordToNumber[reviewStringValue];
          })
          setAverageRating(Math.round(sumOfScores / data.length * 10) / 10);
        } catch (error) {
          // cache it if fail/error;
    
        }
      }
    useEffect(() => {
      getitemreviews(urlReviws);
      }, []);

  const pageurl = typeof window !== 'undefined' ? window.location.href : ''
  let myid_filt = pageurl.split('?');
  let mypageurl = myid_filt[0];
  let property_slug = mypageurl.split('-');
  let id = property_slug[property_slug.length - 1];
  id = id.replace("/", "");


  const [state, setState] = React.useState({
    showMenu: false
  })

  const handlerClick = () => {
    setState({ ...state, showMenu: !state.showMenu })
  }

  // similar properties
  const [propItems, setPropItems] = useState([])
  const getitems = async (url, id) => {
    try {
      const { data } = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
            }})
      if (data && data.length > 0) {
        let filterProperty = data.filter(p => p.id != id)
        setPropItems(filterProperty)
      }
    } catch (error) {
      console.error(error)
    }
  }
  const { loading, error, data: property_details } = useQuery(PROPERTY_DETAILS, {
    variables: { id: id }
  });
  const location = useLocation();
  const thePath = location.pathname
  
  // if (error) {
  //   window.location.replace(process.env.GATSBY_SITE_URL+"/not-found");
  // }
  // if ( typeof property_details?.properties?.length !== 'undefined' ) {
  //   if ( property_details?.properties?.length == 0 ) {
  //     window.location.replace(process.env.GATSBY_SITE_URL+"/not-found");
  //   }
  // }

  // similar properties

  useEffect(() => {
    // similar properties
    const pageurl = typeof window !== 'undefined' ? window.location.href : ''
    let property_slug = pageurl.split('-');
    if (property_details?.properties && property_details?.properties.length > 0) {
      var property = property_details.properties[0];
      //console.log("publish: ",property.publish);
      // if(property?.publish === false) {
      //   navigate('/404')
      // }
      let similar_property_id = property_slug[property_slug.length - 1];
      // let url = process.env.GATSBY_STRAPI_SRC + "/stb-lists/item/Property-details?pid=" + similar_property_id.replace("/#","").split("?")[0];
      var url = process.env.GATSBY_STRAPI_SRC + "/properties?search_type=sales&department=" + property.department + "&_limit=8&_sort=published_at:DESC&publish=true";
      if (pageurl.split('/')[3] == "properties-for-rent" || pageurl.split('/')[3] == "commercial-properties-for-rent") {
        url = process.env.GATSBY_STRAPI_SRC + "/properties?search_type=lettings&department=" + property.department + "&_limit=8&_sort=published_at:DESC&publish=true";
      }

      if (property.address?.area) {
        var splitVal = property.address?.area.split(" ");

        splitVal.map((val, k) => {
          if (k === 0) {
            url = url + "&[display_area_contains]=" + val;
          } else {
            url = url + "&_where[_or][" + (k - 1) + "][display_area_contains]=" + val;
          }

        })

      }

      //&[display_address_contains]=Green&_where[_or][1][display_address_contains]=Community
      // if (property.building) {
      //   url = url + "&building="+property.building;
      // }

      getitems(url, property.id)
    } else{
      //navigate('/404/')
    }
    // similar properties
  }, [property_details]);
  // const [purl , setUrl] = useState(pageurl)
  // const { loading, error, data:property_details } = useQuery(PROPERTY_FOR_SALE, {
  // variables: { url: id }});
  var myMetaTitle = "";
  var myMetaDesc = "";

  
  if (props?.pageContext?.title) {
    myMetaTitle = props.pageContext.title + ' in ' + props.pageContext.display_address;
    myMetaDesc = 'Find ' + props.pageContext.title + ' in ' + props.pageContext.display_address + ' with Dacha Real Estate at AED ' + props.pageContext.price.toLocaleString() + '. Book a viewing with us now.';
  }
  var popularSearch = "Popular_Search_Sale"
  if(property_details && property_details.properties && property_details.properties.length > 0){
    let property = property_details.properties[0];
    if(property.department === "commercial"){
      popularSearch = "Popular_Search_Commercial"
    }else if(property.department === "residential" && property.search_type === "lettings"){
      popularSearch = "Popular_Search_Rent"
    }
  } 

  let language = typeof window !== 'undefined' ? localStorage.getItem('language') : null
  // if (loading) return <div className="gql-loading-screen"><SEO title={myMetaTitle} description={myMetaDesc} /><img loading="lazy" src={Logo} alt="Loading - Dacha" /><p>Loading ...</p></div>;
  const meta_Image = property_details?.properties[0]?.images[0]?.url
  return (
    <>
      {loading ?
        <>
          <div className="gql-loading-screen"><SEO title={myMetaTitle} description={myMetaDesc} /><img loading="lazy" src={Logo} alt="Loading - Dacha" /><p>Loading ...</p></div>
        </>
      :
        property_details?.properties && property_details?.properties.length > 0 ?
          <>
            {/* <SEO title={myMetaTitle} description={myMetaDesc} location={props.location} /> */}
            <div
              className={ `${state.showMenu ? 'open-search-block' : ''} ${language === 'Russian' ? "russian-page" : ""} wrapper details-page`}
            >

              <Header showMenu={state.showMenu} handlerClick={handlerClick} fixed={true} />
              {property_details && property_details.properties && property_details.properties.length > 0 ? property_details.properties.map((data, i) => {
                sessionStorage.setItem('data', JSON.stringify(data))
                sessionStorage.setItem('property_url', pageurl)

                var address = "";
                if(data.address?.county){
                  address += data.address.county+", ";
                }
                if(data.address?.area){
                  address += data.address.area+", ";
                }
                if(data.address?.town){
                  address += data.address.town;
                }

                var floor_area = data.floor_area ? " "+data.floor_area+" sq ft" : "" 
                
                var area = data.address.area ? " "+data.address.area : "" 
                var bath_rooms = data.bathroom ? " "+data.bathroom+" bathrooms" : ""
                var furnished = data.extras?.Furnished === "Y" ? " with fully furnished" : " with unfurnished";
                var meta_desc = area + bath_rooms + furnished+"."

                const metaTitle = _.capitalize(data.title) + ' in ' + _.capitalize(address) + (floor_area); 
                const metaDesc = 'Find ' + _.lowerCase(data.title) + ' in '+ _.capitalize(address) + ' with Dacha Real Estate at AED ' + data.price.toLocaleString() + '. Book a viewing with us now.'+meta_desc
                const logoBlue = `${process.env.GATSBY_SITE_URL}/images/logo-blue.svg`;
                return (
                  <>
                    <SEO image_url={meta_Image} title={metaTitle} description={metaDesc} location={props.location}/>
                    <Helmet>
                <script type="application/ld+json">{`{
                   "@context" : "http://schema.org",
                    "@type" : "Product",
                    "name" : "${metaTitle} | Dacha Real Estate",
                    "image": "${data.images[10]?.url ? data.images[10]?.url : (data.images[1]?.url ? data.images[1]?.url : data.images[0]?.url)}",
                    "description" : "${metaDesc}",
                    "brand" : {
                          "@type" : "Organization",
                          "name" : "Dacha Real Estate",
                          "logo" :" ${logoBlue}"
                      },
                    "aggregateRating": {
                          "@type": "AggregateRating",
                          "ratingValue": "${averageRating}",
                          "reviewCount": "${reviewCount}"
                      },

                    "offers": {
                          "@type": "Offer",
                          "url": "${process.env.GATSBY_SITE_URL}${thePath}",
                          "priceCurrency": "AED",
                          "price": "${data.price}",
                          "availability": "https://schema.org/InStock"
                        }
                      }
          `}</script>
                
        </Helmet>
                    <PropertyBanner data={data} />
                    <ProductContent data={data} />
                    {
                      propItems.length > 0 &&
                      <Similar place={data.address?.area} data={propItems} tag={data.department} />
                    }

                  </>
                )
              }) : ''}
              <div className="footer-section property-details-footer">
                {
                  property_details && property_details.properties && property_details.properties.length > 0 &&
                  <>
                  
                    {property_details.properties?.[0].department == "residential" && property_details.properties?.[0].search_type == "sales" &&
                      <Breadcrumb data={''} tag={`detail-page`} url={`properties/for-sale/in-dubai`} name={`${property_details.properties?.[0].title} - ${property_details.properties?.[0].display_address}`} category={`Properties For Sale`} />
                    }
                    {property_details.properties?.[0].department == "residential" && property_details.properties?.[0].search_type == "lettings" &&
                      <Breadcrumb data={''} tag={`detail-page`} url={`properties/for-rent/in-dubai`}  name={`${property_details.properties?.[0].title} - ${property_details.properties?.[0].display_address}`} category={`Properties For Rent`} />
                    }
                    {property_details.properties?.[0].department == "commercial" && property_details.properties?.[0].search_type == "sales" &&
                      <Breadcrumb data={''} tag={`detail-page`} url={`properties/commercial/for-sale/in-dubai`} name={`${property_details.properties?.[0].title} - ${property_details.properties?.[0].display_address}`} category={`Commercial Properties For Sale`} />
                    }
                    {property_details.properties?.[0].department == "commercial" && property_details.properties?.[0].search_type == "lettings" &&
                      <Breadcrumb data={''} tag={`detail-page`} url={`properties/commercial/for-rent/in-dubai`} name={`${property_details.properties?.[0].title} - ${property_details.properties?.[0].display_address}`} category={`Commercial Properties For Rent`} />
                    }
                    
                  </>
                }
                <NewsLetter />
                <Footer popularSearch={popularSearch}  />
              </div>
              {
                property_details.properties && property_details.properties.length > 0 &&
                <StickyButton pageurl={pageurl} data={property_details.properties[0]} />

              }

            </div>
          </>
        :
        <>
          <NotFound />
        </>
      }
    </>
  )
}


export default PropertyDetailsTemplate