/**
 * Number
 */
export const numberFormat = num => {
  if (!num) return 0
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/format
  return new Intl.NumberFormat("en-US", {}).format(num)
}

/**
 * parseInt
 */
export const filterNumber = str => {
  if (!str) return 0
  str = str.toString()
  return parseInt(str.replace(/[^\d.]/g, ""), 10)
}

/**
 * Interest rate, periods, Present value of loan, Future value of loan, Calculated at start of each period
 */
export const pmt = (rate, periods, present, future = 0, type = 1) => {
  if (isNaN(rate) || isNaN(periods) || !periods || !rate) {
    return 0
  }

  if (rate === 0) {
    return -((present + future) / periods)
  } else {
    var term = Math.pow(1 + rate, periods)
    if (type === 1) {
      return -(
        ((future * rate) / (term - 1) + (present * rate) / (1 - 1 / term)) /
        (1 + rate)
      )
    } else {
      return -((future * rate) / (term - 1) + (present * rate) / (1 - 1 / term))
    }
  }
}


export const calculatePrinciple = (
  propertyValue,
  interestRate,
  downPayment,
  loanTerm
) => {
  const monthlyRate = interestRate / 100 / 12;
  const payments = 12 * loanTerm;

  const x = Math.pow(1 + monthlyRate, payments);
  console.log("calculate_principle", propertyValue, interestRate, downPayment, loanTerm);

  // var calculate_principle = roundNumber(((propertyValue - (propertyValue * downPayment) / 100) * x * monthlyRate) / (x - 1));
  
  var calculate_principle = roundNumber(((propertyValue - downPayment) * x * monthlyRate) / (x - 1));

  // var calculate_principle = PMT(
  //     interestRate / 100 / 12,
  //     roundNumber(loanTerm) * 12,
  //     roundNumber(propertyValue - downPayment),
  //     0,
  //     1
  //   )
  
  return roundNumber(calculate_principle);
  
};

export const calculateMonthlyPayment = (
  propertyValue,
  interestRate,
  downPayment,
  loanTerm
) => {

  //console.log("calculate_payment", propertyValue, interestRate, downPayment, loanTerm);
  var calculate_payment = roundNumber(calculatePrinciple(propertyValue, interestRate, downPayment, loanTerm));

  return calculate_payment;
}

export const roundNumber = num => {
  return Math.round(num * 100) / 100;
};

export const PMT = (rate, periods, present, future, type) => {
  // Credits: algorithm inspired by Apache OpenOffice

  // Initialize type
  var type = (typeof type === 'undefined') ? 0 : type;

  // Evaluate rate (TODO: replace with secure expression evaluator)
  rate = eval(rate);

  // Return payment
  var result;
  if (rate === 0) {
      result = (present + future) / periods;
  } else {
      var term = Math.pow(1 + rate, periods);
      if (type === 1) {
          result = (future * rate / (term - 1) + present * rate / (1 - 1 / term)) / (1 + rate);
      } else {
          result = future * rate / (term - 1) + present * rate / (1 - 1 / term);
      }
  }
  //return -result;
  return result;
}