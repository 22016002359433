import { Link } from "@StarberryUtils";
import React from "react"
import {Form,Nav,Tab} from "react-bootstrap"

import MortgageCalc from '../../calculators/mortgagecalc/';
import StampdutyCalc from '../../calculators/stampdutycalc/';
import RentalyieldCalc from '../../calculators/rentalyieldcalc';
import _ from "lodash"

const Calculator = (props) => {

  const getPOA = (data) => {
    var permit = "";
    if (!_.isEmpty(data?.POA)) {
      if (data.POA == "Y") {
        permit = "PRICE ON APPLICATION";
      }
    }
    return permit;
  }

  var prop_price = getPOA(props.extras) === "" ? props.price : "";

   return (
    <React.Fragment>
      <div className="calculator-block">
        
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <div className="calculator-block-tab">
            <Nav variant="pills">
              <div className="pills-wrap">
                  <Nav.Item>
                    <Nav.Link eventKey="first">Mortgage Calculator</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">Rental Yield Calculator</Nav.Link>
                  </Nav.Item>
              </div>
            </Nav>
            </div>
            <Tab.Content>
            <Tab.Pane eventKey="first">
             <div className="calculator-block-content">
             {/* <p>Curabitur quam orci, sollicitudin at facilisis in, tempus eu risus. Aliquam velit enim, rutrum in odio eu, ultrices fermentum mauris. Cras turpis urna, tincidunt at hendrerit vitae.</p>*/}
              <div className="calculator-block-form">
                <MortgageCalc propertyPrice={prop_price} current={props.current}/>
              </div>
            </div>
        </Tab.Pane>
            <Tab.Pane eventKey="second">
             <div className="calculator-block-content">
              {/* <p>Curabitur quam orci, sollicitudin at facilisis in, tempus eu risus. Aliquam velit enim, rutrum in odio eu, ultrices fermentum mauris. Cras turpis urna, tincidunt at hendrerit vitae.</p>*/}
              <div className="calculator-block-form">
                <RentalyieldCalc propertyValue={props.price}/>
              </div>
            </div>
        </Tab.Pane>
                </Tab.Content>
          </Tab.Container>
      </div>
    </React.Fragment>
  )
}

export default Calculator