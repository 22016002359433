import React, {useEffect} from "react"
import { Link } from "@StarberryUtils";
import { Container, Row, Col, Button } from "react-bootstrap";
import Form from "../../forms/book-a-viewing-form"
import ModalTiny from "react-bootstrap/Modal"
import _ from "lodash"
import "./StickyButton.scss"
import { useStaticQuery, graphql } from "gatsby"
// markup
const StickyButton = (props) => {
  const data = useStaticQuery(graphql`
  query {
    glstrapi {
      peoples {
        Designation
        Email
        Name
        Phone
        Tile_Image {
          url
        }
        URL
        Neg_Id
        reapit_code
        certified_people{
          Designation
          Email
          Name
          Phone
          Tile_Image {
            url
          }
          URL
        }
      }
    }
  }
`);
  let peoples = data.glstrapi.peoples;
  const [negotiator, setNegotiator] = React.useState(null);
  const [modalBookViewformOpen, setBookViewingformOpen] = React.useState(false);
  const [propertyImg, setPropertyImg] = React.useState(process.env.GATSBY_SITE_URL + '/images/property-image.jpg');
  const openBookViewformModal = () => {
    setBookViewingformOpen(true);
  }
  const closeBookViewformModal = () => {
    setBookViewingformOpen(false);
  }
  useEffect(() => {
    if (props.data.images.length > 0) {
      setPropertyImg(props.data.images[0].url)
    }
    if (props.data?.negotiator_id) {
      var people = _.find(peoples, function (o) { return o.reapit_code == props.data.negotiator_id; });
      if (!_.isEmpty(people?.Phone)) {
        people.Phone = people.Phone;
        setNegotiator(people);
      }
    }
    if (props.data.images.length > 0) {
      setPropertyImg(props.data.images[0].url)
    }

  })
  return (

    <React.Fragment>
      <div className="sticky-btn d-lg-none">

        <button className="btn btn-primary" onClick={openBookViewformModal} >Book a Viewing</button>
        {negotiator && negotiator.Phone ?
        <a href={`tel:${negotiator.Phone}`} className="btn btn-dark">Call Agent</a>
        :
        <a href="tel:+971505313003" className="btn btn-dark">Call Agent</a>
        }
        </div>
      <ModalTiny size="lg" show={modalBookViewformOpen} onHide={closeBookViewformModal} className="book-a-viewing-modal share-to-friend-modal">
        <ModalTiny.Header className="contact-close-btn">
          <span class="close-menu" onClick={closeBookViewformModal}><i class="close-icon"></i></span>
          <ModalTiny.Title className="w-100"><h2 className="text-center mb-0">Book a Viewing</h2></ModalTiny.Title>
        </ModalTiny.Header>
        <ModalTiny.Body className="event-section">
          <Form
            property_img={propertyImg}
            property_id={props.data && props.data.id ? props.data.id : ''}
            page_url={props.pageurl}
            property_title={props.data && props.data.title ? props.data.title : ''}
            negotiator={props.data && props.data.address?.area ? `${props.data.address}, ${props.data.address.area}` : ''} /></ModalTiny.Body>

      </ModalTiny>
    </React.Fragment>
  )
}

export default StickyButton